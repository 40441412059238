import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Profane Soul!`}</li>
      <li parentName="ul">{`Damned soul!`}</li>
      <li parentName="ul">{`Cut/Nick/Break your thread`}</li>
      <li parentName="ul"></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      